<template>
    <b-card>
  
      <b-row>
  
        <!-- User Info: Left col -->
        <b-col
          cols="12"
          xl="5"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
            variant="light-primary"
            badge
            :text="avatarText(userPackage.full_name)"
            class="badge-minimal"
            badge-variant="success"
              :src="userPackage.profile_image"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-2">
                  {{ userPackage.full_name }}
                </h4>
                <span class="card-text">
                  <b-badge class="profile-badge " variant="light-success">{{ userPackage.email }}</b-badge>
                </span>
              </div>
              <div class="d-flex flex-wrap">
              
              <b-button
                variant="outline-success"
                class=""
                @click="goToClientWhatsaap()"
              >
                whatsaap Client
              </b-button>
            </div>
              
            </div>
          </div>
  
        </b-col>
  
        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="7"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Entreprise:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userPackage.company_name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                icon="FlagIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Adresse:</span>
              </th>
              <td class="pb-50">
                {{ userPackage.company_address }}
              </td>
            </tr>
           
            <tr>
              <th>
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Contact:</span>
              </th>
              <td>
                {{ userPackage.phone_number }}
              </td>
            </tr>
            
            <tr>
              <th>
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Assignée à:</span>
              </th>
              <td v-if="userpackage.assign_to == null">
                Non assignée
              
              </td>
              <td v-else>
                {{ userpackage.assign_to.full_name }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
</template>
  
<script>
  import {
    BCard, BButton, BAvatar, BRow, BCol,BBadge
  } from 'bootstrap-vue'
  import { avatarText } from '@core/utils/filter'
  import {mapGetters} from 'vuex'
  
  export default {
    components: {
      BCard, BButton, BRow, BCol, BAvatar,BBadge
    },
    // props: {
    //   userData: {
    //     type: Object,
    //     required: true,
    //   },
    // },
    data() {
      return{
        avatarText,
        userPackage: {},
        userpackage:{}
        

      }
    },
    created() {
      this.userPackage = JSON.parse(localStorage.getItem('client'))
      this.userpackage = this.getDirectPackages
      console.log('Userpackage',this.userpackage)

    },
    computed: {
      ...mapGetters('packages', ['getDirectPackages']),
    },
    methods: {
      goToClientWhatsaap() {
      window.open(`https://wa.me/${this.userPackage.phone_number}?text=Bonjour Mr/Mme!*${this.userPackage.full_name}*`)
    },
    }
    
  }
</script>
  
  <style>
  
  </style>
  