<template>
    <div
      v-if="
        getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'rh' ||
        getUser.role.slug === 'assistant-rh'
      "
    >
    <b-breadcrumb class="mb-2">
      <b-breadcrumb-item to="/work/providers/recommandations/business">Packages</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link
          :to="{
            name: 'business-orders',
            params: { id: this.$route.query.packageId } 
           
          }"
          >Commandes</router-link
        >
      </b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link
          :to="{
            name: 'admin-orders-business-recurring-details',
            params: { id: this.$route.query.packageId } 
           
          }"
          >Détails</router-link
        >
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Affectations</b-breadcrumb-item>
    </b-breadcrumb>

      <div
        v-if="isPageLoading"
        class="d-flex justify-content-center mb-1"
        style="padding-top: 30vh"
      >
        <b-spinner variant="info" style="width: 3rem; height: 3rem" class="mr-1" />
      </div>
      <div v-else>
      <b-row>
        <b-col cols="12" lg="4" md="5">
          <b-row>
            <b-col cols="12" xl="12" lg="12" md="12">
              <b-card class="p-2">
                <div class="profile-image-wrapper">
                  <div class="profile-image text-center p-1">
                    <b-avatar
                      variant="light-primary"
                      :text="avatarText(userPackageAssign.user.full_name)"
                      class="badge-minimal"
                      :src="userPackageAssign.user.profile_image"
                      size="104px"
                      rounded
                    />
                  </div>
                </div>
                <div class="text-center">
                  <h3>{{ userPackageAssign.user.full_name }}</h3>
                  <h6 class="text-muted">
                    {{ userPackageAssign.user.email }}
                  </h6>
                  <b-badge class="profile-badge" variant="light-success">
                    {{ userPackageAssign.user.phone_number }}
                  </b-badge>
                </div>
  
                <hr class="mb-2" />
  
                <!-- follower projects rank -->
                <div>
                  <h6 class="text-info text-justify mb-2">DETAILS</h6>
                  <div class="d-flex justify-content-between align-items-center mb-1">
                    <div>
                      <h5 class="font-weight-bolder">Téléphone:</h5>
                    </div>
                    <div>
                      <h6 class="text-info">
                        {{ userPackageAssign.user.phone_number }}
                      </h6>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-1">
                    <div>
                      <h5 class="font-weight-bolder">Mail:</h5>
                    </div>
                    <div>
                      <h6 class="text-info ml-2">
                        {{ userPackageAssign.user.email }}
                      </h6>
                    </div>
                  </div>
               
                </div>
  
  
                <!--/ follower projects rank -->
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" lg="8" md="7">
          <b-row>
            <b-col cols="12" xl="12" md="12">
              <b-card>
                <b-tabs pills>
                  <b-tab active>
                    <template #title>
                      <feather-icon
                        icon="ShoppingBagIcon"
                        class="text-white mr-1"
                        size="20"
                      />
                      Commandes
                    </template>
                    <hr />
                    <b-card>
                      <b-row>
                        <!-- Per Page -->
  
                        <b-col
                          cols="12"
                          md="6"
                          class="d-flex align-items-center justify-content-start mb-2"
                        >
                          <b-button
                            variant="primary"
                            @click="reLoadDataRecurringOrdersAction()"
                          >
                            <div v-if="isReLoadDataPackageOrders">
                              <span> Chargement ... </span>
                              <b-spinner small />
                            </div>
                            <span v-else class="text-nowrap font-medium-1">Actualiser</span>
                          </b-button>
                        </b-col>
                      </b-row>
                     
                       <b-row>
                        <b-col cols="12" md="12" lg="12" class="mb-md-0 mb-2">
                          <b-row>
                            <b-col cols="12" sm="6" md="6" lg="5">
                              <label for="">Service</label>
                              <v-select
                                id="role"
                                v-model="payloadFilterOrder.service_id"
                                :reduce="(service) => service.id"
                                label="name"
                                :options="recurringServicesOption"
                                class="invoice-filter-select"
                              >
                                <template v-slot:no-options>
                                  <b-spinner
                                    v-if="isServicesRecurringWithoutPaginationLoading"
                                    style="width: 2.5rem; height: 2.5rem"
                                    class="align-middle text-info"
                                  />
  
                                  <span v-else class="mt-1 font-medium-1"
                                    >Aucun service
                                  </span>
                                </template>
                              </v-select>
                            </b-col>
                            <b-col cols="12" sm="6" md="6" lg="5">
                              <label for="">Statut</label>
                              <v-select
                                v-model="payloadFilterOrder.status"
                                :options="statusOptions"
                                class="invoice-filter-select"
                                :reduce="(item) => item.value"
                              >
                                <template #selected-option="{ label }">
                                  <span class="text-truncate overflow-hidden">
                                    {{ label }}
                                  </span>
                                </template>
                              </v-select>
                            </b-col>
                            <b-col cols="12" sm="6" md="2" lg="2" class="mt-2">
                              <b-button
                                variant="primary"
                                @click="applyFilterDirectOrderAction()"
                              >
                                <div v-if="isReLoadFilterDataRecurringOrders">
                                  <span> Chargement ... </span>
                                  <b-spinner small />
                                </div>
  
                                <span v-else class="text-nowrap font-medium-1"
                                  >Filtrer</span
                                >
                              </b-button>
                            </b-col>
                            
                          </b-row>
                        </b-col>
                      </b-row>
                      <vue-good-table
                        class="mt-2"
                        :columns="columns"
                        :rows="command"
                        :rtl="direction"
                        :is-loading="isDirectPackageOrdersLoading"
                      >
                      <template slot="emptystate">
                      <div class="text-center text-info">
                        <span> {{ isDirectPackageOrdersLoading ? "" : "Liste vide" }}</span>
                      </div>
                    </template>
  
                    <template slot="loadingContent">
                      <div class="text-center text-info my-2">
                        <b-spinner class="align-middle text-info" />
                      </div>
                    </template>
                        <template slot="table-row" slot-scope="props">
                          <div
                            v-if="props.column.field == 'recurring_service'"
                            class="align-middle text-center"
                          >
                            <span class="font-small-5 text-info">
                              {{ props.row.recurring_service.name }}
                            </span>
                          </div>
  
                          <div
                            v-else-if="props.column.field == 'employee_salary'"
                            class="text-nowrap text-center align-middle"
                          >
                            <span
                              class="d-block align-middle text-info text-center font-small-5"
                              >{{ props.row.employee_salary }} FCFA</span
                            >
                          </div>
  
                          <div
                            v-else-if="props.column.field == 'setStatus'"
                            class="align-middle text-center"
                          >
                            <b-badge
                              pill
                              :variant="`light-${resolveOrderStatusVariant(
                                props.row.status,
                                props.row.placement_cost_paid,
                                props.row.search_employee
                              )}`"
                              class="text-capitalize text-nowrap font-medium-1"
                            >
                              {{ props.row.setStatus }}
                            </b-badge>
                          </div>
  
                          <div
                            v-else-if="
                              props.column.field == 'action' &&
                              getUser.role.slug !== 'charge-de-clientele'
                            "
                            class="align-middle text-center"
                          >
                            <b-dropdown
                              variant="link"
                              toggle-class="text-decoration-none"
                              no-caret
                            >
                              <template v-slot:button-content>
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="25"
                                  class="text-info align-middle mr-25"
                                />
                              </template>
                              <b-dropdown-item
                                class="itemActions cursor-pointer"
                                @click="putRecurringOrdersInStoreAction(props.row)"
                              >
                                <feather-icon
                                  :id="`details${props.row.id}`"
                                  icon="EyeIcon"
                                  class="text-info mr-1"
                                  size="25"
                                />
                                <span>Détails</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </template>
                      </vue-good-table>
  
                      <div class="d-flex justify-content-between mt-2 flex-wrap">
                        <b-button
                          :disabled="metaData.prev_page_url == null"
                          variant="outline-secondary"
                          @click="applyLoadMoreDirectOrderAction(metaData.prev_page_url)"
                        >
                          <span class="text-white text-nowrap font-medium-2"
                            >Page précédente</span
                          >
                        </b-button>
  
                        <div>
                          <span class="text-white font-medium-2"
                            >{{ metaData.current_page }} sur
                            {{ metaData.last_page }} pages</span
                          >
                        </div>
                        <div>
                          <span class="text-white font-medium-2">Total :{{ total }}</span>
                        </div>
  
                        <b-button
                          :disabled="metaData.next_page_url == null"
                          variant="outline-secondary"
                          @click="applyLoadMoreDirectOrderAction(metaData.next_page_url)"
                        >
                          <span class="text-nowrap font-medium-1 text-white"
                            >Page suivante</span
                          >
                        </b-button>
                      </div>
                    </b-card>
                  </b-tab>
  
                  
                </b-tabs>
              </b-card>
            </b-col>
          </b-row>
  
          <!-- Order assignment Modal -->
          <b-modal
            id="modal-order-assignment"
            cancel-variant="outline-secondary"
            hide-footer
            centered
            no-close-on-backdrop
            no-close-on-esc
            title="Assignation d'assistant RH"
          >
            <validation-observer #default="{}" ref="orderAssignmentForm">
              <!-- Form -->
              <b-form
                class="p-2"
                autocomplete="off"
                @reset.prevent="hideModalAssigment()"
                @submit.prevent="applyPackageAssignment"
              >
                <b-form-group label="Liste des assistants RH " label-for="role">
                  <validation-provider
                    #default="{ errors }"
                    name="Assistant RH"
                    rules="required"
                  >
                    <b-form-select
                      v-if="
                        AssistantRHOptions.length === 1 && AssistantRHOptions[0].disabled
                      "
                      id="rh_id"
                      v-model="payload.rh_id"
                      :options="AssistantRHOptions"
                    />
                    <v-select
                      v-else
                      id="role"
                      v-model="payload.rh_id"
                      :reduce="(businessManager) => businessManager.id"
                      label="full_name"
                      :options="AssistantRHOptions"
                    >
                      <span slot="no-options" @click="$refs.select.open = false">
                        Chargement...
                      </span>
                    </v-select>
  
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
  
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    :disabled="isOrderAssignmentLoading"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    <div v-if="isOrderAssignmentLoading">
                      <span> Chargement ... </span>
                      <b-spinner small />
                    </div>
                    <span v-else>Assigner</span>
                  </b-button>
                  <b-button
                    type="reset"
                    variant="outline-secondary"
                    @click="hideModalAssigment()"
                  >
                    Annuler
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-modal>
          <b-modal
            id="modal-action-times"
            cancel-variant="outline-secondary"
            hide-footer
            centered
            no-close-on-backdrop
            no-close-on-esc
          >
            <b-row class="">
              <b-col md="6" cols="12">
                <p class="card-text mb-25 font-medium-1">
                  Date de la commande:
                  <span class="text-warning">
                    {{ moment(currentOrder.created_at).locale("fr").format("llll") }}
                  </span>
                </p>
                <p class="card-text mb-25 font-medium-1">
                  Date d'affectation:
                  <span class="text-warning">
                    {{
                      !currentOrder.assign_to
                        ? "Non effectué"
                        : !currentOrder.auto_assignment
                        ? moment(currentOrder.assign_at).locale("fr").format("llll")
                        : "Auto assignation"
                    }}
                  </span>
                </p>
                <p class="card-text mb-25 font-medium-1">
                  Date du feedback:
                  <span class="text-warning">{{
                    currentOrder.feedback_make_at
                      ? moment(currentOrder.feedback_make_at).locale("fr").format("llll")
                      : "Non effectué"
                  }}</span>
                </p>
                <p class="card-text mb-25 font-medium-1">
                  Dernière recommandation:
                  <span class="text-warning">
                    {{
                      currentOrder.recommandation_make_at
                        ? moment(currentOrder.recommandation_make_at)
                            .locale("fr")
                            .format("llll")
                        : "Non effectué"
                    }}
                  </span>
                </p>
              </b-col>
              <b-col md="6" cols="12">
                <p class="card-text mb-25 font-medium-1">
                  Dernière proposition:
                  <span class="text-warning">
                    {{
                      currentOrder.proposition_make_at
                        ? moment(currentOrder.proposition_make_at)
                            .locale("fr")
                            .format("llll")
                        : "Non effectué"
                    }}
                  </span>
                </p>
                <p class="card-text mb-25 font-medium-1">
                  Date de déploiement:
                  <span class="text-warning">{{
                    currentOrder.employee_contract_started_date
                      ? moment(currentOrder.employee_contract_started_date)
                          .locale("fr")
                          .format("llll")
                      : "Non effectué"
                  }}</span>
                </p>
              </b-col>
            </b-row>
          </b-modal>
        </b-col>
      </b-row>
      <!-- Deploy Employee Modal -->
      <b-modal
        id="modal-update-deploiement-date"
        cancel-variant="outline-secondary"
        hide-footer
        centered
        no-close-on-backdrop
        no-close-on-esc
        title="Date de déploiement d'employé"
      >
        <validation-observer #default="{}" ref="deployEmployeeForm">
          <!-- Form -->
          <b-form
            class="p-2"
            autocomplete="off"
            @reset.prevent="hideModalUpdateDeployEmployeeDate()"
            @submit.prevent="applyUpdateDeploymentEmployee()"
          >
            <b-form-group label="Date de déploiement" label-for="role">
              <validation-provider
                #default="{ errors }"
                name="date de déploiement"
                rules="required"
              >
                <flat-pickr v-model="employee_contract_started_date" class="form-control" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                :disabled="isUpdateDeployementDateLoading"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <div v-if="isUpdateDeployementDateLoading">
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span v-else>Modifier la date</span>
              </b-button>
              <b-button type="reset" variant="outline-secondary"> Annuler </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
    </div>
    <div v-else>
      <not-authorized />
    </div>
  </template>
  
  <script>
  import router from "@/router";
  import { ref, onUnmounted } from "@vue/composition-api";
  import {
    BAlert,
    BLink,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BSpinner,
    BFormSelect,
    BForm,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BTabs,
    BTab,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormTextarea,
  } from "bootstrap-vue";
  import { avatarText } from "@core/utils/filter";
  import vSelect from "vue-select";
  import flatPickr from "vue-flatpickr-component";
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { VueGoodTable } from "vue-good-table";
  import store from "@/store/index";
  import NotAuthorized from "@/layouts/components/NotAuthorized.vue";
  
  import UserViewUserInfoCard from "@/layouts/components/UserViewUserInfoCard.vue";
  
  export default {
    name: "OrderIndex",
    components: {
      BAlert,
      BLink,
      BSpinner,
      BRow,
      BCol,
      BFormInput,
      BCard,
      BCardHeader,
      BCardBody,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BBadge,
      BPagination,
      BTooltip,
      BFormSelect,
      BForm,
      BFormGroup,
      ValidationProvider,
      ValidationObserver,
      VueGoodTable,
      flatPickr,
      vSelect,
      BDropdown,
      BDropdownItem,
      NotAuthorized,
      UserViewUserInfoCard,
      BTabs,
      BTab,
      BBreadcrumb,
      BBreadcrumbItem,
      BFormTextarea,
    },
    props: {
      userpackage: {
        type: Object,
        default: () => ({}),
      },
    },
  
    data() {
      return {
        isSuivisLoading : false,
        isPageLoading: true,
        avatarText,
        userPackage: {
          fullname: "",
          email: "",
          phone_number: "",
          company_address: "",
          company_name: "",
          is_activated: "",
          phone_number: "",
        },
        recurringServicesOption: [],
        isLoading: true,
        total: "",
        isUpdateDeployementDateLoading: false,
        tableItems: [],
        employee_contract_started_date: "",
        statusOptions: [
          {
            label: "En att. feedback",
            value: "AF",
          },
          {
            label: "En att. recommandation",
            value: "AR",
          },
          {
            label: "En att. proposition",
            value: "AP",
          },
          {
            label: "Non Payé",
            value: "NP",
          },
          {
            label: "Employé proposé",
            value: 1,
          },
          {
            label: "Contract émis",
            value: 2,
          },
          {
            label: "Contract approuvé",
            value: 3,
          },
          {
            label: "Actif",
            value: 4,
          },
          {
            label: "Terminée",
  
            value: 5,
          },
          {
            label: "Résilié",
            value: -1,
          },
        ],
  
        managerOptions: [],
  
        customerOptions: [],
  
        payloadFilterOrder: {
          service_id: "",
          status: "",
        },
  
        AssistantRHOptions: [],
  
        isOrderAssignmentLoading: false,
  
        payload: {
          rh_id: "",
        },
  
        PackageToAssign: {},
        currentOrder: {},
        required,
        rows1: [],
        command: [],
        columns: [
          {
            label: "Service demandé",
            field: "recurring_service",
            filterOptions: {
              enabled: false,
              placeholder: "Rechercher par Service",
              filterFn(data, filterString) {
                return data.name.toLowerCase().includes(filterString.toLowerCase());
              },
            },
          },
          {
            label: "Salaire net",
            field: "employee_salary",
            filterOptions: {
              enabled: false,
              placeholder: "Rechercher par Client",
              filterFn(data, filterString) {
                return data.full_name.toLowerCase().includes(filterString.toLowerCase());
              },
            },
          },
          {
            label: "Status",
            field: "setStatus",
            filterOptions: {
              enabled: false,
              placeholder: "Rechercher par Status",
              filterDropdownItems: [
                "En attente de feedback",
                "En attente de recommandation",
                "En attente de proposition",
                "Résilié",
                "Employé proposé",
                "Non Payé",
                "Contract émis",
                "Contract approuvé",
                "Actif",
                "Terminée",
              ],
              filterFn(data, filterString) {
                return data.toLowerCase() === filterString.toLowerCase();
              },
            },
          },
  
          {
            label: "Action",
            field: "action",
          },
        ],
        dir: false,
        isReLoadDataPackageOrders: false,
        isReLoadFilterDataRecurringOrders: false,
  
        //SUIVI EMPLOYE
        rows: [],
        config: { mode: 'range' },
        isMakeEmployeeSuivi: false,
        isUpdateEmployeeSuivi: false,
        total: '',
        isShowSendSuiviButton: false,
        isSendSuiviRapport: false,
        isEmployeesSuivi: true,
        isUnPublishedSuivi: false,
        isEmployeeLoading : false,
        employeeOptions: [],
        employeeSuiviOptions: [],
        selectEmployee: [],
        // payloadFilter: {
        //   employee_id: '',
        // },
        payloadSuivi: {
          suivis_date: '',
          resum: '',
          employee_id: '',
        },
        payloadGetSuivi: {
          employee_id: '',
        },
        updatePayload: {
          suivis_date: '',
          resum: '',
          suivi_id: '',
        },
        rows_employees_suivis: [],
        
        columns_employees_suivis: [
          {
            label: 'Date du suivi',
            field: 'suivis_date',
          },
          {
            label: 'Suivi effectué par',
            field: 'suivis_make_by',
          },
          {
            label: 'Contenu du suivi',
            field: 'resum',
          },
          {
            label: 'Action',
            field: 'action',
          },
        ],
        isFilterSuivi: false,
        rangeDate: null,
        isReLoadFilterDataEmployees: false,
        payloadFilter :{
          start_date: "",
          end_date: "",
          type: "",
          package_id: "",
          employee_id: ""
        },
        other_resum: '',
        resumSuivi: '',
        selectedOption: 'others',
         resumOptions: [
          {
            value: 'Employé injoignable',
            text: 'Employé injoignable',
          },
          {
            value: 'RAS',
            text: 'Rien a signalé',
          },
          {
            value: 'others',
            text: 'Autres',
          },
        ],
      };
    },
    computed: {
      ...mapGetters("packages", ["getDirectPackageOrders", "getCurrentPackage"]),
      ...mapGetters("orders", ["getDirectOrders", "getDirectOrderActif"]),
      ...mapGetters([
        "isDirectPackageOrdersLoading",
        "isDirectOrdersFilterLoading",
        "isCustomersWithoutPaginationLoading",
        "isDirectOrdersActifLoading", "isEmployeesWithoutPaginationLoading",
        "isSuivisLoading",
        "isActifEmployeesLoading"
      ]),
      ...mapGetters("auth", ["getUser"]),
      ...mapGetters("roles", ["getBusinessManagersList", "getActifEmployees"]),
      ...mapGetters("customers", ["getCustomersWithoutPagination"]),
  
      ...mapGetters("services", [
        "getRecurringServices",
        "getRecurringServicesWithoutPagination",
      ]),
       ...mapGetters('professionals', [
        'getEmployeesWithoutPagination',
        'getActifEmployees'
      ]),
      ...mapGetters(["isServicesRecurringWithoutPaginationLoading"]),
      ...mapGetters("suivis", ["getSuivis"]),
      
      metaData() {
        const meta = {
          prev_page_url: "",
          next_page_url: "",
          current_page: "",
          last_page: "",
          current_page_url: "",
        };
        if (this.getDirectPackageOrders != null) {
          meta.prev_page_url = this.getDirectPackageOrders.prev_page_url;
          meta.next_page_url = this.getDirectPackageOrders.next_page_url;
          meta.current_page = this.getDirectPackageOrders.current_page;
          meta.last_page = this.getDirectPackageOrders.last_page;
          meta.current_page_url = `${this.getDirectPackageOrders.path}?page=${this.getDirectPackageOrders.current_page}`;
          this.total = this.getDirectPackageOrders.total;
        }
        return meta;
      },
  
      metaDataSuivi() {
        const metaSuivi = {
          prev_page_url: "",
          next_page_url: "",
          current_page: "",
          last_page: "",
          current_page_url: "",
        };
        
         if (this.getSuivis != null) {
          metaSuivi.prev_page_url = this.getSuivis.prev_page_url
          metaSuivi.next_page_url = this.getSuivis.next_page_url
          metaSuivi.current_page = this.getSuivis.current_page
          metaSuivi.last_page = this.getSuivis.last_page
          metaSuivi.current_page_url = `${this.getSuivis.path}?page=${this.getSuivis.current_page}`
          this.totalSuivi = this.getSuivis.total
        }
        return metaSuivi;
      },
      
  
     
      isEmployeesWithoutPaginationLoading(val) {
        if (val === false) {
          this.getEmployeesWithoutPagination.forEach(element => {
            this.employeeOptions.push({
              id: element.id,
              name: element.full_name,
            })
          })
        }
      },
      direction() {
        if (store.state.appConfig.isRTL) {
          this.dir = true;
          return this.dir;
        }
        this.dir = false;
        return this.dir;
      },
    },
    watch: {
      selectEmployee(val){
        console.log('valeur',val)
        if(val !== null){
          console.log(this.selectEmployee.id)
          this.$store.commit('SET_IS_SUIVIS_LOADING', true)
        this.payloadGetSuivi.employee_id = this.selectEmployee.id
         if (this.getUser.role.slug === 'super-administrateur') {
          this.payloadGetSuivi.suivi_type = 'employee'
        }
        this.payloadGetSuivi.method = "GET"
          this.getSuiviAction({payload: this.payloadGetSuivi})
          .then((response) => {
          this.isSuivisLoading = false
            this.rangeDate = null
            this.$store.commit('SET_IS_SUIVIS_LOADING', false)
            this.rows_employees_suivis = response.data.data
            // console.log(this.rows_employees_suivis)
  
          })
          .catch((error) => {
        this.isSuivisLoading = false
            this.$store.commit('SET_IS_SUIVIS_LOADING', false)
            console.log(error)
          })
        }
        else{
          this.rows_employees_suivis = []
        }
      },
       isServicesRecurringWithoutPaginationLoading(val) {
        if (val === false) {
          this.getRecurringServicesWithoutPagination.forEach((element) => {
            this.recurringServicesOption.push({
              id: element.id,
              name: element.name,
            });
          });
        }
      },
       isSuivisLoading(val) {
        if (val === false) {
          this.rows = this.getSuivis.data
        }
      },
  
       rangeDate(val) {
        console.log(val)
        if (val != null && val.split('to').length === 2) {
          this.applyFilterSuiviAction(val)
          console.log(val.split('to').length)
        }
      },
      payloadFilterOrder: {
        handler(newValue, oldValue) {
          const keys = Object.keys(newValue);
          keys.forEach((key) => {
            if (newValue[key] == null) {
               this.packagesCommand()
              newValue[key] = "";
              console.log(this.payloadFilterOrder);
            }
          });
        },
        deep: true,
      },
  
      payloadFilter: {
        handler(newValue, oldValue) {
          const keys = Object.keys(newValue);
          keys.forEach((key) => {
            if (newValue[key] == null) {
               this.packagesCommand()
              newValue[key] = "";
              console.log(this.payloadFilter);
            }
          });
        },
        deep: true,
      },
    
       isDirectPackageOrdersLoading(val) {
        if (val === false) {
          this.command = this.customTab(this.getDirectPackageOrders.data)
          console.log('pakage',this.getDirectPackageOrders.data)
        }
      },
  
      // isDirectPackageOrdersLoading(val) {
  
      //   if (val === false) {
      //     this.rows = this.customTab(this.getDirectPackageOrders.data)
      //     console.log('pakage',this.getDirectPackageOrders.data)
      //   }
      // },
      // getDirectPackageOrders(newValue, oldValue) {
      //   if (oldValue.data.length != 0 && oldValue.data.length != newValue.data.length) {
      //     this.rows = this.customTab(this.getDirectPackageOrders.data)
      //   }
      // },
      isDirectPackageOrdersLoading(val) {
        if (val === false) {
          this.rows = this.customTab(this.getDirectPackageOrders.data);
          console.log("packaaage", this.getDirectPackageOrders.data);
        }
      },
      isCustomersWithoutPaginationLoading(val) {
        if (val === false) {
          this.getCustomersWithoutPagination.forEach((element) => {
            this.customerOptions.push({
              id: element.id,
              name: element.customer_full_name,
            });
          });
        }
      },
    },
    created() {
      console.log("SUIVIS", this.getSuivis)
      this.applygetAssistantRHListAction();
      this.isLoading = true;
      this.reloadPackage()
      this.packagesCommand(this.$route.params.id);
      if (this.getCurrentPackage == null) {
        this.userPackageAssign = JSON.parse(localStorage.getItem("clientPackage"));
      } else {
        this.userPackageAssign = this.getCurrentPackage;
      }
  
      console.log("Get package in store", this.userPackageAssign);
      console.log("Assign package", this.userPackageAssign)
        ?(this.rows = this.customTab(this.getDirectPackageOrders.data))
        : null;
  
      if (this.isDirectPackageOrdersLoading === false) {
        this.rows = this.customTab(this.getDirectPackageOrders.data);
      }
      this.getDirectPackageOrders != null
        ? (this.rows = this.customTab(this.getDirectPackageOrders.data))
        : null;
      
  
      this.getSuivis !== null
        ? (this.rows = this.getSuivis.data)
        : null
  
     
    },
    mounted() {
       if (this.getRecurringServicesWithoutPagination.length != 0) {
        this.getRecurringServicesWithoutPagination.forEach((element) => {
          this.recurringServicesOption.push({
            id: element.id,
            name: element.name,
          });
        });
      }
      if (this.getEmployeesWithoutPagination.length != 0) {
        this.getEmployeesWithoutPagination.forEach(element => {
          this.employeeOptions.push({
            id: element.id,
            name: element.full_name,
          })
        })
      }
      this.applyGetActifEmployees()
    },
    methods: {
      ...mapActions("packages", [
        "getDirectPackageOrdersAction",
        "assignAPackageToBusinessManagerAction",
        "assignAPackageToAssistantRHAction",
        "autoAssignmentRHAction",
        "getDirectPackagesAction",
        "filterDirectOrderAction",
        "loadMoreDirectOrderAction",
        "getPackageByIdAction",
      ]),
      ...mapActions("roles", ["getAssistantRHListAction", "getAssistantRHListAction", 'loadMoreDirectOrdersActifAction',
        'getDirectOrdersActifAction',
        'filterDirectOrdersActifAction',]),
      ...mapActions("orders", [
        "assignAnOrderToBusinessManagerAction",
        "changeEmployeeDeploymentAction",
        "getDirectOrdersAction",
        "getDirectOrdersAssignedToBusinessManagerAction",
        // "loadMoreDirectOrderAction",
        
        "markDirectOrderAsFinishedAction",
      ]),
      ...mapActions("professionals", ["confirmPlacementCostPaidAction", "getActifEmployeesAction"]),
      ...mapActions("customers", ["getCustomersWithoutPaginationAction"]),
       ...mapActions('suivis', [
        'markSuiviAction',
        'getSuiviAction',
        'updateSuiviAction',
        'filterSuiviAction',
        'getSuiviUnPublishedSuiviAction',
        'sendSuiviAction',
      ]),
  
      applyGetDirectPackagesAction() {
        this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", true);
        this.getDirectPackagesAction()
          .then((response) => {
            this.clientPackage = response.data;
            this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
            console.log(response);
          })
          .catch(() => {
            this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
          });
      },
  
      applyMarkDirectOrderAsFinished() {
        this.$swal({
          title: "Clôturer le dossier",
          text: "Êtes-vous sûr de vouloir clôturer le dossier ?",
          icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: "Non",
          allowOutsideClick: false,
          confirmButtonText: "Oui",
          customClass: {
            confirmButton: "btn btn-warning",
            cancelButton: "btn btn-outline-primary ml-1",
          },
          buttonsStyling: false,
          preConfirm: async () =>
            await this.markDirectOrderAsFinishedAction(this.directOrder.id),
        })
          .then((response) => {
            if (response.isConfirmed) {
              this.getDirectOrderAction(this.directOrder.id).then(async (response) => {
                this.directOrder = response.data;
                this.directOrder = this.resolveStatusText(this.directOrder);
                this.$store.commit("orders/SET_DIRECT_ORDER", this.directOrder);
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Succès",
                      icon: "CheckIcon",
                      text: "Contrat clôturé avec succès",
                      variant: "success",
                    },
                  },
                  {
                    position: "top-center",
                  }
                );
                this.getDirectOrdersAction();
              });
            }
          })
          .catch((error) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Erreur",
                  icon: "AlertTriangleIcon",
                  text: error,
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          });
      },
  
      applyConfirmedPlacementCostPaidAction(id) {
        this.$swal({
          title: "Confirmer Frais de Placement",
          text: "Êtes-vous sûr de vouloir valider les frais de placement ?",
          icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: "Non",
          allowOutsideClick: false,
          confirmButtonText: "Oui",
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-outline-outline ml-1",
          },
          buttonsStyling: false,
          preConfirm: async () => {
            await this.confirmPlacementCostPaidAction(this.directOrder.id);
            return await this.getDirectOrdersAction();
          },
        })
          .then((response) => {
            if (response.isConfirmed) {
              this.getDirectOrderAction(this.directOrder.id).then(async (response) => {
                this.directOrder = response.data;
                this.directOrder = this.resolveStatusText(this.directOrder);
                this.$store.commit("orders/SET_DIRECT_ORDER", this.directOrder);
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Succès",
                      icon: "CheckIcon",
                      text: "Frais de placement confirmé avec succès",
                      variant: "success",
                    },
                  },
                  {
                    position: "top-center",
                  }
                );
                this.getDirectOrdersAction();
              });
            }
          })
          .catch((error) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Erreur",
                  icon: "AlertTriangleIcon",
                  text: error,
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          });
      },
  
      openCustomerContract(userPackageAssign) {
        this.$router.push({
          path: "/preview/pdf",
          query: {
            makeActionOnPage: false,
            actionType: null,
            isSigned: true,
            pdf: userPackageAssign.contract_file_url,
          },
        });
      },
  
      // getpackageById() {
      //   this.getpackageByIdAction(packageId)
      //     .then((response) => {
      //       console.log(response);
      //     })
      //     .console.log(error);
      // },
      sendPlecementFeesPaymentLink() {
        window.open(`https://wa.me/${
          JSON.parse(localStorage.getItem("client")).phone_number
        }?text=Bonjour Mr/Mme!*${JSON.parse(localStorage.getItem("client")).full_name}*
                      Merci de cliquer sur le lien suivant afin de payer les frais de placement pour
                      vos commandes.
                      \n\n http://localhost:3000/direct/${
                        this.$route.params.id
                      }/placement`);
      },
      copyPlacementFeesPaymentLink() {
        navigator.clipboard
          .writeText(
            `Bonjour Mr/Mme!*${
              JSON.parse(localStorage.getItem("client")).full_name
            }*Merci de cliquer sur le lien suivant afin de payer les frais de placement pour vos commandes
       \n\n http://localhost:3000/direct/${this.$route.params.id}/placement`
          )
          .then(() => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Succès",
                  icon: "CheckIcon",
                  text: "Lien copié avec succès",
                  variant: "success",
                },
              },
              {
                position: "top-center",
              }
            );
          });
      },
  
      newCommand() {
        this.$router.push(
          `/orders/direct/new/command?search_employee=true&client_hide=true&packageId=${this.$route.params.id}`
        );
      },
      newCommandGestion() {
        this.$router.push(
          `/orders/direct/new/commandgestion?search_employee=false&client_hide=true&packageId=${this.$route.params.id}`
        );
      },
      goToClientWhatsaap() {
        window.open(
          `https://wa.me/${this.userPackage.user.phone_number}?text=Bonjour Mr/Mme!*${this.userPackage.user.full_name}*`
        );
      },
      packagesCommand() {
        this.$store.commit("SET_IS_DIRECT_PACKAGE_ORDERS_LOADING", true);
        this.getDirectPackageOrdersAction(this.$route.params.id)
          .then((response) => {
            this.$store.commit("SET_IS_DIRECT_PACKAGE_ORDERS_LOADING", false);
            this.isPageLoading = false
            this.command = this.customTab(response.data.data);
            console.log("Les commandes ", this.command);
          })
  
          .catch((error) => {
            this.isPageLoading = false
            this.$store.commit("SET_IS_DIRECT_PACKAGE_ORDERS_LOADING", false);
            console.log(error);
          });
      },
      applyUpdateDeploymentEmployee() {
        this.$bvModal.hide("modal-update-deploiement-date");
        this.isUpdateDeployementDateLoading = true;
        this.$swal
          .fire({
            title: "Modifier la date de déploiement d'employé",
            text: "Êtes-vous sûr de vouloir modifier la date de déploiement  cet employé ?",
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            cancelButtonText: "Non",
            allowOutsideClick: false,
            confirmButtonText: "Oui",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-primary ml-1",
            },
            buttonsStyling: false,
            preConfirm: () =>
              this.changeEmployeeDeploymentAction({
                orderId: this.currentOrder.id,
                payload: { employee_deployment_date: this.employee_contract_started_date },
              })
                .then((response) => {
                  this.isUpdateDeployementDateLoading = false;
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: "Succès",
                        icon: "CheckIcon",
                        text: response.message,
                        variant: "success",
                      },
                    },
                    {
                      position: "top-center",
                    }
                  );
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(`${error.response.data.message}`);
                }),
          })
          .then((response) => {
            if (response.isConfirmed) {
              this.hideModalUpdateDeployEmployeeDate();
              this.getDirectOrdersAction();
            } else {
              this.hideModalUpdateDeployEmployeeDate();
            }
          })
          .catch((error) => {
            this.isUpdateDeployementDateLoading = false;
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Erreur",
                  icon: "AlertTriangleIcon",
                  text: error.message,
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          });
      },
      hideModalUpdateDeployEmployeeDate() {
        (this.employee_contract_started_date = ""),
          (this.isUpdateDeployementDateLoading = false);
        this.$bvModal.hide("modal-update-deploiement-date");
      },
      putRecurringOrdersInStoreAction(directOrder) {
        this.$store.commit("orders/SET_DIRECT_ORDER", directOrder);
        this.$router.push(
          `/business/recommandation/orders/${directOrder.id}/details?packageId=${directOrder.package_id}`
        );
      },
  
      reLoadDataRecurringOrdersAction() {
        this.payloadFilterOrder.service_id = "";
        this.payloadFilterOrder.status = "";
  
        this.isReLoadDataPackageOrders = true;
        this.$store.commit("SET_IS_DIRECT_PACKAGE_ORDERS_LOADING", true);
        this.command = [];
  
        this.getDirectPackageOrdersAction(this.$route.params.id)
          .then((response) => {
            this.isReLoadDataPackageOrders = false;
            this.$store.commit("SET_IS_DIRECT_PACKAGE_ORDERS_LOADING", false);
            this.command = this.customTab(response.data.data);
          })
          .catch((error) => {
            this.isReLoadDataPackageOrders = false;
            this.$store.commit("SET_IS_DIRECT_PACKAGE_ORDERS_LOADING", false);
            console.log(error);
          });
        // this.getDirectOrdersAction()
        //   .then(response => {
        //     this.isReLoadDataRecurringOrders = false
        //     this.$store.commit('SET_IS_DIRECT_PACKAGES_LOADING', false)
        //   })
        //   .catch(error => {
        //     this.isReLoadDataRecurringOrders = false
        //     this.$store.commit('SET_IS_DIRECT_ORDERS_LOADING', false)
        //   })
      },
  
     applyLoadMoreDirectOrderAction(url) {
        this.$store.commit("SET_IS_DIRECT_PACKAGE_ORDERS_LOADING", true);
        this.rows = [];
        this.loadMoreDirectOrderAction(url)
          .then(() => {
            this.$store.commit("SET_IS_DIRECT_PACKAGE_ORDERS_LOADING", false);
          })
          .catch(() => {
            this.$store.commit("SET_IS_DIRECT_PACKAGE_ORDERS_LOADING", false);
          });
      },
      showUpdateDeploiementDateModal(item) {
        console.log(item);
        this.$bvModal.show("modal-update-deploiement-date");
        this.employee_contract_started_date = item.employee_contract_started_date;
        this.currentOrder = item;
      },
      showActionTimesModal(item) {
        this.$bvModal.show("modal-action-times");
        this.currentOrder = item;
      },
  
      applyGetDirectOrdersAssignedToBusinessManagerAction(id) {
        this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", true);
        this.getDirectOrdersAssignedToBusinessManagerAction(id)
          .then(() => {
            this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
          })
          .catch(() => {
            this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
          });
      },
      applyGetDirectOrderAction() {
        this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", true);
        this.getDirectOrdersAction()
          .then(() => {
            this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
          })
          .catch(() => {
            this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
          });
      },
  
      applygetAssistantRHListAction() {
        this.getAssistantRHListAction().then((response) => {
          if (response.data.length === 0) {
            this.AssistantRHOptions.push({
              value: "",
              disabled: true,
              text: "La liste des assistants RH est vide",
            });
          } else {
            response.data.forEach((element) => {
              this.AssistantRHOptions.push(element);
            });
          }
        });
      },
  
      hideModalAssigment(){
        this.$bvModal.hide("modal-order-assignment");
            this.payload = {
              rh_id: "",
            };
      },
      applyPackageAssignment() {
        this.isOrderAssignmentLoading = true;
        this.assignAPackageToAssistantRHAction({
          packageId: this.PackageToAssign.id,
          payload: this.payload,
        })
          .then(async (response) => {
             this.userPackageAssign = response.data.data
            console.log("aply assignment", this.getCurrentPackage);
            console.log("aply assignment RESPONSE",  this.getCurrentPackage);
            // this.applyLoadMoreDirectOrderAction(this.metaData.current_page_url)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Succès",
                  icon: "CheckIcon",
                  text: response.data.message,
                  variant: "success",
                },
              },
              {
                position: "top-center",
              }
            );
            console.log('RESPONSE ASSIGN',response)
            this.$store.commit("packages/SET_CURRENT_PACKAGE", response.data.data);
            localStorage.setItem("clientPackage", JSON.stringify(response.data.data));
            this.reloadPackage()
            this.applyGetDirectPackagesAction();
            this.isOrderAssignmentLoading = false;
            this.hideModalAssigment()
          })
          .catch((error) => {
            this.isOrderAssignmentLoading = false;
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Erreur",
                  icon: "AlertTriangleIcon",
                  text: error.response.data.message,
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          });
      },
  
      applyAutoAssignmentAction(){
        this.autoAssignPayload ={
          rh_id: this.getUser.id
            }
        this.$swal
          .fire({
            title: "Auto assignment",
            text: "Êtes-vous sûr de vouloir gérer ce package vous même ?",
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            cancelButtonText: "Non",
            allowOutsideClick: false,
            confirmButtonText: "Oui",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-primary ml-1",
            },
            buttonsStyling: false,
            preConfirm: () =>
              this.autoAssignmentRHAction({
              packageId: this.$route.params.id,
              payload: this.autoAssignPayload    
            })
              .then((response) => {
  
              })
              .catch((error) => {
                this.$swal.showValidationMessage(`${error.response.data.message}`);
              }),
          })
        .then(response => {
          this.userPackageAssign = response.data
            console.log('aply assignment', this.getCurrentPackage)
          console.log("Autoassignement", response)
          if (response.isConfirmed){
          this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Package ylomi direct assigné avec succès.',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
            console.log('RESPONSE ASSIGN', response)
            this.$store.commit('packages/SET_CURRENT_PACKAGE', response.data)
            localStorage.setItem('clientPackage', JSON.stringify(response.data))
            this.reloadPackage()
            this.isOrderAssignmentLoading = false
          })
          .catch(error => {
            this.isOrderAssignmentLoading = false
            console.log(error)
            // this.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: 'Erreur',
            //       icon: 'AlertTriangleIcon',
            //       text: error.response.data.message,
            //       variant: 'danger',
            //     },
            //   },
            //   {
            //     position: 'top-center',
            //   },
            // )
          })
  
      },
  
      reloadPackage() {
        this.isload = true
        this.getPackageByIdAction(this.$route.params.id)
          .then(response => {
            this.userPackageAssign = response.data
            localStorage.removeItem('clientPackage')
            localStorage.setItem('clientPackage', JSON.stringify(this.userPackageAssign))
            this.$store.commit('packages/SET_CURRENT_PACKAGE', this.userPackageAssign)
            this.isload = false
          })
          .catch(() => {
            console.log(error)
          })
      },
  
      applyFilterDirectOrderAction() {
        const allIsNUl = Object.values(this.payloadFilterOrder).every((el) => el === "");
        if (allIsNUl) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Warning",
                icon: "CheckIcon",
                text: "Veuillez sélectionner au moins un champ du filtre.",
                variant: "warning",
              },
            },
            {
              position: "top-center",
            }
          );
        } else {
          this.isReLoadFilterDataRecurringOrders = true;
          this.$store.commit("SET_IS_DIRECT_PACKAGE_ORDERS_LOADING", true);
          // this.$store.commit('SET_IS_DIRECT_ORDERS_FILTER_LOADING', true)
          this.command = [];
          this.filterDirectOrderAction({
            id: this.$route.params.id,
            payload: this.payloadFilterOrder
            })
            .then((response) => {
              this.isReLoadFilterDataRecurringOrders = false;
              this.$store.commit("SET_IS_DIRECT_PACKAGE_ORDERS_LOADING", false);
              // this.$store.commit('SET_IS_DIRECT_ORDERS_FILTER_LOADING', false)
              this.command = this.customTab(response.data)
              this.total = this.command.length
             
            })
            .catch((error) => {
               this.isReLoadFilterDataRecurringOrders = false;
               this.$store.commit("SET_IS_DIRECT_PACKAGE_ORDERS_LOADING", false);
            this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: "danger",
                        icon: "AlertTriangleIcon",
                        text: error.response.data.message,
                        variant: "warning",
                      },
                    },
                    {
                      position: "top-center",
                    }
                  );
              })
        }
      },
      openContract(data) {
        this.$router.push({
          path: "/preview/pdf",
          query: {
            makeActionOnPage: false,
            actionType: null,
            isSigned: true,
            pdf: data.contract_file_url,
          },
        });
      },
  
      sendContractByWhatsApp(data) {
        window.open(`https://wa.me/${data.user.phone_number}?text=Nos salutions! 
  Merci de cliquer sur le lien suivant afin de consulter le contract de prestation de votre commande ${data.recurring_service.name}. \n\n *${data.contract_file_url}*`);
      },
  
      resolveOrderStatusVariant: (status, placementCostPaid, searchEmployee) => {
        if (status === -1) return "danger";
        if (status === 0 && placementCostPaid === false && searchEmployee === true) {
          return "danger";
        }
        if (status === 0) return "warning";
        if (status === 1) return "info";
        if (status === 2) return "warning";
        if (status === 3 || status === 4) return "success";
        return "primary";
      },
  
      customTab(array) {
        const tab = [];
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          if (
            element.status === 0 &&
            element.placement_cost_paid === false &&
            element.search_employee === true
          ) {
            element.setStatus = "Non Payé";
            tab.push(element);
            continue;
          }
  
          if (element.status === 0 && element.feedback === "") {
            element.setStatus = "En attente de feedback";
            tab.push(element);
            continue;
          }
  
          if (
            element.status === 0 &&
            element.feedback !== "" &&
            element.recommandations_count === 0
          ) {
            element.setStatus = "En attente de recommandation";
            tab.push(element);
            continue;
          }
  
          if (
            element.status === 0 &&
            element.feedback != "" &&
            element.propositions_count === 0
          ) {
            element.setStatus = "En attente de proposition";
            tab.push(element);
            continue;
          }
          if (element.status === -1) {
            element.setStatus = "Résilié";
            tab.push(element);
            continue;
          }
          if (element.status === 1) {
            element.setStatus = "Employé proposé";
            tab.push(element);
            continue;
          }
          if (element.status === 2) {
            element.setStatus = "Contract émis";
            tab.push(element);
            continue;
          }
          if (element.status === 3) {
            element.setStatus = "Contract approuvé";
            tab.push(element);
            continue;
          }
          if (element.status === 4) {
            element.setStatus = "Actif";
            tab.push(element);
            continue;
          }
          if (element.status === 5) {
            element.setStatus = "Terminée";
            tab.push(element);
            continue;
          }
        }
        return tab;
      },
  
      //SUIVIS EMPLOYES
      applyGetActifEmployees(){
        this.isEmployeeLoading = true
        this.getActifEmployeesAction({packageId: this.$route.params.id})
        .then(response =>{
          this.isEmployeeLoading = false
          console.log(response)
          if (response.data.length === 0) {
            this.employeeSuiviOptions.push({
              value: "",
              disabled: true,
              text: "Aucun employé actif",
            });
          } else {
            response.data.forEach((element) => {
              this.employeeSuiviOptions.push({
                comment: element.comment,
                employee_salary: element.employee_salary,
                full_name: element.recommandation.employee.full_name,
                id: element.recommandation.employee.id,
                recommandation_id: element.id,
                employee_desired_salary: element.recommandation.employee_salary,
                employee: element.recommandation.employee,
              });
        })
         }
        })
        .catch(error =>{
          this.isEmployeeLoading = false
          console.log(error)
        })
      },
      applyFilterEmployeesAction() {
        const allIsNUl = Object.values(this.payloadFilter).every(
          el => el === '',
        )
        if (allIsNUl) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Warning',
                icon: 'CheckIcon',
                text: 'Veuillez sélectionner au moins un champ du filtre.',
                variant: 'warning',
              },
            },
            {
              position: 'top-center',
            },
          )
        } else {
          this.isReLoadFilterDataEmployees = true
          this.rows = []
          this.filterDirectOrdersActifAction(this.payloadFilter)
            .then(response => {
              this.isReLoadFilterDataEmployees = false
              this.rows = response.data
              this.total = this.rows.length
            })
            .catch(error => {
              this.isReLoadFilterDataEmployees = false
  
            })
        }
      },
      hideModalEmployeeSuivi() {
        this.isMakeEmployeeSuivi = false
        this.$bvModal.hide('modal-make-employee-suivi')
        this.selectEmployee = [],
        this.resumSuivi = '',
        this.other_resum = '',
        this.payloadSuivi = {
          suivis_date: '',
          employee_id: '',
          resum: '',
        }
      },
      hideModalUpdateEmployeeSuivi() {
        this.$bvModal.hide('modal-update-employee-suivi')
        this.updatePayload = {
          suivis_date: '',
          suivi_id: '',
          resum: '',
        }
      },
      updateEmployeeSuiviAction(item) {
        this.updatePayload = {
          suivis_date: item.suivis_date,
          resum: item.resum,
          suivi_id: item.id,
        }
      },
      makeEmployeeSuiviAction(item) {
        this.payload.employee_id = item.employee_id
        this.payload.user_id = item.user_id
      },
      employeeSuivisDetailsAction(item) {
        this.isSuivisLoading = true
        this.isShowSendSuiviButton = false
        if (this.columns_employees_suivis.length > 4) {
          this.columns_employees_suivis.splice(2, 2)
        }
        if (this.getUser.role.slug === 'super-administrateur') {
          this.payloadGetSuivi.suivi_type = 'employee'
        }
        this.payloadGetSuivi.employee_id = this.selectEmployee.id
        this.payloadGetSuivi.method = "GET"
        this.getSuiviAction({
          payload: this.payloadGetSuivi,
        })
          .then(response => {
            this.isSuivisLoading = false
            this.rows_employees_suivis = response.data.data
          })
          .catch(error => {
            this.isSuivisLoading = false
            console.log(error)
          })
      },
  
      applySendSuiviRapportAction() {
        const payload = {}
        if (this.getUser.role.slug === 'super-administrateur') {
          payload.suivi_type = 'employee'
        }
        this.isSendSuiviRapport = true
        this.sendSuiviAction(payload)
          .then(response => {
            this.$bvModal.hide('modal-employee-suivis')
            this.isSendSuiviRapport = false
            if (response.data.length == 0) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Warning',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'warning',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            }
          })
          .catch(error => {
            this.isSendSuiviRapport = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          })
      },
      applyGetUnPublishedSuivi() {
        const payload = {}
        if (this.getUser.role.slug === 'super-administrateur') {
          payload.suivi_type = 'employee'
        }
        this.isUnPublishedSuivi = true
        this.getSuiviUnPublishedSuiviAction(payload)
          .then(response => {
            this.isUnPublishedSuivi = false
             this.isSuivisLoading = false
            this.isEmployeesSuivi = false
            if (this.columns_employees_suivis.length == 4) {
              this.columns_employees_suivis.splice(
                2,
                0,
                {
                  label: 'Client',
                  field: 'client',
                },
                {
                  label: 'Employé',
                  field: 'employee',
                },
              )
            }
            if (response.data.length == 0) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Warning',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'warning',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            } else {
              this.isShowSendSuiviButton = true
              this.rows_employees_suivis = response.data
              this.$bvModal.show('modal-employee-suivis')
            }
          })
  
          .catch(error => {
            this.isUnPublishedSuivi = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: "Erreur lors de l'envoi du rapport de suivi, Réessayer!",
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          })
      },
      applyFilterSuiviAction(val) {
        this.isShowSendSuiviButton = false
        this.payloadFilter = {
          start_date: val.split('to')[0],
          end_date: val.split('to')[1],
          type: 'SE',
          package_id: this.$route.params.id,
          employee_id: ""
        }
        this.isFilterSuivi = true
        if(this.payloadFilter.employee_id !== ""){
            this.payloadFilter.employee_id = this.selectEmployee.id
        }
       
        this.filterSuiviAction(this.payloadFilter)
          .then(response => {
            this.isSuivisLoading = false
            this.isFilterSuivi = false
            this.isEmployeesSuivi = false
            // if (this.columns_employees_suivis.length == 4) {
            //   this.columns_employees_suivis.splice(
            //     2,
            //     0,
            //     {
            //       label: 'Client',
            //       field: 'client',
            //     },
            //     {
            //       label: 'Employé',
            //       field: 'employee',
            //     },
            //   )
            // }
            this.rows_employees_suivis = response.data
             this.totalSuivi = this.rows_employees_suivis.length
            this.$bvModal.show('modal-employee-suivis')
          }).catch(error => {
            this.isFilterSuivi = false
            if (error.response.status !== 422) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: 'Erreur lors du filtrage, Réessayer!',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            }
          })
      },
       applyGetSuiviReloadAction() { 
       this.rangeDate = null
        this.isSuivisLoading = true
        this.$store.commit('SET_IS_SUIVIS_LOADING', true)
        if(this.selectEmployee !== null){
          this.payloadGetSuivi.employee_id = this.selectEmployee.id
        }
        
        if(this.selectEmployee = null){
          this.isSuivisLoading = false
          this.$store.commit('SET_IS_SUIVIS_LOADING', false)
          this.rows_employees_suivis = []
        }
        else{
          this.getSuiviAction({payload: this.payloadGetSuivi})
          .then((response) => {
          this.isSuivisLoading = false
            this.$store.commit('SET_IS_SUIVIS_LOADING', false)
            this.rows_employees_suivis = response.data
            console.log(this.rows_employees_suivis)
          })
          .catch((error) => {
        this.isSuivisLoading = false
            this.$store.commit('SET_IS_SUIVIS_LOADING', false)
            console.log(error)
          })
        }
        
      },
  
      // applyGetDirectOrdersActifAction() {
      //   this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', true)
      //   this.getDirectOrdersActifAction()
      //     .then(() => {
      //       this.rangeDate = null
      //       this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', false)
      //     })
      //     .catch(() => {
      //       this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', false)
      //     })
      // },
  
      applyLoadMoreDirectOrdersActifActionAction(url) {
        this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', true)
        this.rows = []
        this.loadMoreDirectOrdersActifAction(url)
          .then(() => {
            this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', false)
          })
          .catch(() => {
            this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', false)
          })
      },
  
      applyMakeEmployeeSuivi() {
        console.log(this.selectEmployee)
        this.payloadSuivi.employee_id =this.selectEmployee.employee.id
        this.$refs.EmployeeSuivi.validate().then(success => {
          if (success) {
            this.isMakeEmployeeSuivi = true
            if (this.getUser.role.slug === 'super-administrateur') {
              this.payloadSuivi.suivi_type = 'employee'
            }
             if (this.resumSuivi === 'others') {
              this.payloadSuivi.resum = this.other_resum
            } else {
              this.payloadSuivi.resum = this.resumSuivi
            }
            this.markSuiviAction(this.payloadSuivi)
              .then(response => {
                console.log(response.data.employee_id)
                this.employeeSuivisDetailsAction(response.data.employee_id)
                localStorage.setItem("employee", JSON.stringify(this.employeeSuivisDetailsAction(response.data.employee_id)));
                console.log(response)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: response.message,
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                this.hideModalEmployeeSuivi()
              })
              .catch(error => {
                console.log(error)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erreur',
                      icon: 'AlertTriangleIcon',
                      text: error.response.data.message,
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                this.isMakeEmployeeSuivi = false
              })
          }
        })
      },
      applyupdateEmployeeSuiviAction() {
        this.$refs.UpdateEmployeeSuivi.validate().then(success => {
          if (success) {
             
            this.isUpdateEmployeeSuivi = true
            this.updateSuiviAction({
              payload: this.updatePayload,
              suiviId: this.updatePayload.suivi_id,
            })
              .then(response => {
                this.isUpdateEmployeeSuivi = false
                this.payloadGetSuivi.employee_id = response.data.employee_id
                if (this.getUser.role.slug === 'super-administrateur') {
                  this.payloadGetSuivi.suivi_type = 'employee'
                }
                this.getSuiviAction({
                  payload: this.payloadGetSuivi,
                }).then(response => {
                  this.isEmployeesSuivi = false
                  this.rows_employees_suivis = response.data.data
                })
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: response.message,
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                this.hideModalUpdateEmployeeSuivi()
              })
              .catch(error => {
                console.log(error)
                this.isUpdateEmployeeSuivi = false
              })
          }
        })
      },
    },
  };
  </script>
  
  <style>
  i.icon-code {
    display: none !important;
  }
  
  .vgt-select {
    background: transparent !important;
    border-color: #404656 !important;
  }
  
  .itemActions :hover {
    background: transparent !important;
  }
  </style>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  
  .invoice-filter-select {
    min-width: 200px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-good-table.scss";
  
  .v-list .v-list-item--active {
    background-color: green !important;
  }
  
  .v-list .v-list-item--active .v-list-item__title {
    color: #ffd54f !important;
  }
  </style>
  